@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/MaterialIcons-Regular.eot");

  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("fonts/MaterialIcons-Regular.woff") format("woff"),
    url("fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ibm-plex-sans-v7-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("./fonts/ibm-plex-sans-v7-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-sans-v7-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-regular.svg#IBMPlexSans")
      format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/ibm-plex-sans-v7-latin-700.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("./fonts/ibm-plex-sans-v7-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-700.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 21px;

  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

:root {
  --dark: #292929;
  --darker: #1e1e1e;
  --darkest: #000000;
  --focus: #404040;
  --accent: #fad000;
  --dark-text: #6f6f6f;
  --text: white;
  --error: #ff9042;

  --radius: 4px;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark);
  color: var(--text);
}

* {
  box-sizing: border-box;
}

button {
  font-family: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  cursor: pointer;
}
