.markerCluster {
  border-radius: 70px;
  font-size: 1vw;
  color: #000;
  text-transform: uppercase;
}

.markerClusterCircle {
  background-color: #fad000;
  padding: 1px;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 1.5s;
  opacity: 1;
}

.markerClusterChild {
  background: none;
  display: flex;
}

.markerClusterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.circle {
  animation: fadein 1.5s;
}

.leaflet-pane {
  z-index: 1;
}

.leaflet-container {
  font-family: inherit;
  font-size: 0.75rem;
}

.leaflet-top {
  z-index: 2;
}
